import React, { useEffect, useState } from "react";

import withLayout from "../../layouts/withLayout";
import Sidenav from "../../layouts/adminLayout/sidenav";
import { Card, Table, Modal, Upload, Image, Popover, Badge, Tag, Spin, Space } from "antd";
import { InboxOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { states } from '../../helpers/statestore';
import emptyState from "../../assets/images/empty-state.png"

import {
    MdAddTask,
    MdAttachMoney,
    MdBarChart,
    MdFileCopy,
    MdOutlinePersonRemoveAlt1,
    MdOutlinePersonOff,
    MdGroups,
    MdGroupOff,
    MdGroup,
    MdAddCircle,
    MdAddCircleOutline,
    MdOutlineSettingsSuggest,
    MdArrowDropDownCircle,
    MdChecklist,
    MdCheckCircleOutline,
} from "react-icons/md";
import Endpoint, { baseContentURL } from "../../utils/endpoint";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Spinner from "../../components/spinner";
import { SphereSpinner } from "react-spinners-kit";
import { ScaleLoader } from "react-spinners";
import $ from "jquery"
import { enquireScreen } from 'enquire-js';
import TreeCompo from "../../components/tree";
import { ToastContainer, toast } from 'react-toastify';

const { Dragger } = Upload;


const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const ServiceCategoryManagement = () => {
    const [emptyStateShow, setemptyStateShow] = useState(true);
    const [tableContent, setTableContent] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentSearchQuery, setCurrentSearchQuery] = useState("");
    const [cardDetails, setCardDetails] = useState([]);
    const [locationPayloadArr, setlocationPayloadArr] = useState([]);
    const [open, setOpen] = useState(false);
    const [basicSpin, setbasicSpin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [openPricePlan, setopenPricePlan] = useState(false);
    const [openPriceSetup, setopenPriceSetup] = useState(false);
    const [openAddServiceLocation, setopenAddServiceLocation] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [previewImage, setpreviewImage] = useState("");
    const [saving, setsaving] = useState(false);
    const [visibilityIndicator, setvisibilityIndicator] = useState(1);
    const [openPopIndex, setOpenPopIndex] = useState(null);
    const [selectedServiceCategory, setselectedServiceCategory] = useState({});
    const [pricePlanArr, setpricePlanArr] = useState([]);
    const [newLocationsArr, setnewLocationsArr] = useState([]);
    const [markedServicingArr, setmarkedServicingArr] = useState([]);
    const [showLocationList, setshowLocationList] = useState(false);
    const [pricingSetupCard, setpricingSetupCard] = useState(false);
    const [featureList, setfeatureList] = useState([]);
    const [deletePlanModal, setdeletePlanModal] = useState(false);
    const [categogryId, setcategogryId] = useState(0);
    const [modalText, setmodalText] = useState("");
    const [pricingPlanId, setpricingPlanId] = useState(0);
    const [pricingPlanAction, setpricingPlanAction] = useState("Apply ");
    const [inputValues, setInputValues] = useState({
        checkbox: false,
        text: '',
    });
    const [openPop, setOpenPop] = useState(false);
    // const hidePop = () => {
    //     setOpenPop(false);
    // };
    const hidePop = () => {
        setOpenPopIndex(null);
    };
    // const handleOpenPopChange = (newOpen) => {
    //     setOpenPop(newOpen);
    // };
    const handleOpenPopChange = (index) => {
        setOpenPopIndex(openPopIndex === index ? null : index);
    };
    const handleInput = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setInputValues({
            ...inputValues,
            [name]: value,
        });
    };
const clearImage = () => {
    setpreviewImage("")
    setFileList([])
}
    const showModal = () => {
        setIsModalOpen(true);
        setmodalText("Add")
        setpreviewImage("")
        setInputValues({
            categoryName: "",
            categoryCaption: "",
            description: "",
            badgeValue: ""
        });
    };


    const showAddServiceLocationModal = () => {
        setopenAddServiceLocation(true);
    };
    
    const handleOk = () => {
        setIsModalOpen(false);
        setopenAddServiceLocation(false);
        setopenPriceSetup(false);
        setdeletePlanModal(false)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setopenPricePlan(false);
        setopenAddServiceLocation(false);
        setopenPriceSetup(false);
        setpricingSetupCard(false)
        setdeletePlanModal(false)
    };
    const handleCancelDelete = () => {
        setdeletePlanModal(false)
        setpricingPlanId(0)
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 2000);

    };
    const handleChange = ({ fileList: newFileList }) => {

        console.log(newFileList, "newFileList")
        setFileList(newFileList)
        //if (!file.url && !file.preview) {
        //let yyty = await getBase64(file.originFileObj);
        handlePreview(newFileList[0])
        //}
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setpreviewImage(file.url || file.preview);
    };
    const data = tableContent?.map((item, index) => {
        return {
            name: item?.name?.length > 30
                ? item?.name.substring(0, 30) + "..."
                : item?.name,
            subCaption: item?.subCaption,
            badgeText:
                item?.badgeText?.length > 40
                    ? item?.badgeText.substring(0, 40) + "..."
                    : item?.badgeText,
            hostName: item?.hostName,
            description: item?.description?.length > 20 ? item?.description.substring(0, 20) + "..." : item?.description,
            image: (
                <Image
                    width={30}
                    src={baseContentURL + item?.imageUrl}
                    style={{borderRadius:"25%"}}
                />
            ),
            servicingStates: (
                <Popover
                    content={<a onClick={() => hidePop()}>Close</a>}
                    title="Title"
                    trigger="click"
                    open={openPopIndex === index}
                    onOpenChange={() => handleOpenPopChange(index)}
                    placement="bottom"
                >
                    {/* <button className="btn btn-primary btn-sm">Click me</button> */}
                    {/* <span> <MdArrowDropDownCircle color="#2a67fb" size={20}/></span> */}
                    <span className="outfit-500" style={{ color: "#128f46", cursor: "pointer" }}><i style={{ color: "#128f46", fontSize: "20px" }} class="fa-solid fa-location-dot" /> Locations</span>
                </Popover>
            ),
            icon: (
                // <button className="btn btn-success btn-sm outfit-500">Manage <i class="fa-solid fa-square-pen"/></button>
                <div>
                    {/* <MdOutlineSettingsSuggest
                        style={{ height: "32px", width: "32px" }}
                        color="#2a67fb"
                    /> */}
                    {/* <Tag onClick={() => toggleVisibility(item, 2)} color={'green'} className="outfit-400" key={index} style={{fontSize:"12px"}}>
                        <i class="fa-solid fa-location-dot" /> 
                    </Tag> */}
                    <Tag onClick={() => openEditCategory(item)} color={'volcano'} className="outfit-400" key={index} style={{ fontSize: "12px" }}>
                        <i class="fa-solid fa-edit" />
                    </Tag>
                    <Tag onClick={() => toggleVisibility(item, 3)} color={'geekblue'} className="outfit-500" key={index} style={{ fontSize: "12px" }}>
                        {/* Pricing  */}
                        {/* <i class="fa-solid fa-coins"/> */}
                        <i class="fa-solid fa-location-dot" /> 

                    </Tag>
                    {/* <Popover
                        content={<a onClick={() => hidePop()}>Close</a>}
                        title="Title"
                        trigger="click"
                        open={openPopIndex === index}
                        onOpenChange={() => handleOpenPopChange(index)}
                        placement="bottom"
                    >
                        <Tag color={'green'} className="outfit-400" key={index} style={{ fontSize: "12px" }}>
                            <i class="fa-solid fa-location-dot" /> 
                        </Tag>
                    </Popover> */}
                    
                    {/* <button onClick={() => toggleVisibility(item, 2)} className="btn btn-warning btn-sm outfit-500" style={{fontSize:"12px"}}>Manage  <i  class="fa-solid fa-gears" /></button> */}
                   
                </div>
                
            ),
        };
    });

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Caption",
            dataIndex: "subCaption",
            key: "subCaption",
        },
        // {
        //     title: "Badge Value",
        //     dataIndex: "badgeText",
        //     key: "badgeText",
        // },
        // {
        //     title: "Description",
        //     dataIndex: "description",
        //     key: "description",
           
        // },
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            // render: () => <a href="#">Edit</a>,
        },
        // {
        //     title: "Service Locations",
        //     dataIndex: "servicingStates",
        //     key: "servicingStates",
        // },
        {
            title: "",
            dataIndex: "icon",
            key: "icon",
            // render: () => <a href="#">Edit</a>,
        },
    ];
    const serviceplanscolumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Price",
            dataIndex: "subCaption",
            key: "subCaption",
        },
       
        {
            title: "",
            dataIndex: "icon",
            key: "icon",
            // render: () => <a href="#">Edit</a>,
        },
    ];


    const fetchAllServiceCategories = () => {
        $('#preloader').fadeIn();

            Endpoint.getAllServiceCategories()
            .then((res) => {
                console.log(res.data, "res");
                setCardDetails(res.data);
                setTableContent(res.data);
                $('#preloader').delay(450).fadeOut('slow');
            })
            .catch((err) => {
                console.log(err)
                $('#preloader').delay(450).fadeOut('slow');
            })
    };
    const openEditCategory = (data) => {
        console.log(data)
        setIsModalOpen(true);
        setmodalText("Update")
        setpreviewImage(baseContentURL + data?.imageUrl)
        setInputValues({
            categoryName: data?.name,
            categoryCaption: data?.subCaption,
            description: data?.description,
            categoryId: data?.id,
            serviceCategoryType: data?.serviceCategoryType,
            badgeValue: data?.badgeText
        });

    }
    const handleUpdateServiceCategory = () => {
        setsaving(true)
        console.log(fileList[0])
        let formData = new FormData();

        formData.append("Id", inputValues?.categoryId)
        formData.append("Name", inputValues?.categoryName)
        formData.append("SubCaption", inputValues?.categoryCaption)
        formData.append("BadgeText", inputValues?.badgeValue)
        formData.append("Description", inputValues?.description)
        formData.append("ImageUrl", fileList[0]?.originFileObj)
        formData.append("ImageUrlAlt", null)
        formData.append("ServiceCategoryType", 1)


        Endpoint.updateServiceCategory(formData)
            .then((res) => {
                console.log(res?.data)
                if (res?.data?.id > 0) {
                    setsaving(false)
                    setIsModalOpen(false)
                    $('#preloader').fadeIn('slow');
                    fetchAllServiceCategories();
                }
                else {
                    setsaving(false)
                    setIsModalOpen(false)
                    fetchAllServiceCategories();
                    // $('#preloader').fadeIn('slow');
                    // alert("Something went wrong")
                }

            })
            .catch((err) => {
                console.log(err)
                setsaving(false)
                setIsModalOpen(false)
                fetchAllServiceCategories();

                // alert("Something went wrong")
            })
    }
    const handleCreateServiceCategory = () => {
        setsaving(true)
        console.log(fileList[0])
        let formData = new FormData();

        formData.append("Name", inputValues?.categoryName)
        formData.append("SubCaption", inputValues?.categoryCaption)
        formData.append("BadgeText", inputValues?.badgeValue)
        formData.append("Description", inputValues?.description)
        formData.append("ImageUrl", fileList[0]?.originFileObj)
        formData.append("ImageUrlAlt", null)
        formData.append("ServiceCategoryType", 1)
        
        
        Endpoint.createServiceCategory(formData)
        .then((res) => {
            console.log(res?.data)
            if(res?.data?.id > 0){
                setsaving(false)
                setIsModalOpen(false)
                $('#preloader').fadeIn('slow');
                fetchAllServiceCategories();
            }
            else{
                setsaving(false)
                setIsModalOpen(false)
                $('#preloader').fadeIn('slow');
                alert("Something went wrong")
            }
            
        })
        .catch((err) => {
            console.log(err)
            alert("Something went wrong")
        })
    }
    const toggleVisibility = async (data, indicator) => {
        console.log(data, "Selected")
        setselectedServiceCategory(data)
        await fetchServiceLocationStrings(data?.id, indicator);

        // // setvisibilityIndicator(indicator)
        // if(indicator == 2){
        //     await fetchServiceLocationStrings(data?.id, indicator);
        //     // setopenPricePlan(true)
        // }
        // else if (indicator == 3) {
        //     await fetchServiceLocationStrings(data?.id, indicator);
        //     // setopenPricePlan(true)
        // }
    }
    const togglePlanType = () => {
        // const checkbox = document.getElementById("checkbox");
        const professional = document.getElementById("professional");
        const master = document.getElementById("master");
        const basic = document.getElementById("basic");

        // checkbox.addEventListener("click", () => {
            basic.textContent = basic.textContent === "$199.99" ? "$19.99" : "$199.99";
            professional.textContent =
                professional.textContent === "$249.99" ? "$24.99 " : "$249.99";
            master.textContent = master.textContent === "$399.99" ? "$39.99" : "$399.99";
        // });
    }

    const fetchPlansByLocation = (data) => {
        setpricingSetupCard(false)
        setLoading(true)
        Endpoint.getPlansByLocation(selectedServiceCategory?.id, data)
        .then((res) => {
            console.log(res?.data)
            setpricePlanArr(res?.data)
            setLoading(false)
            if(res?.data?.length > 0){
                setemptyStateShow(false)
            }
            else{
                setemptyStateShow(true)
            }
        })
        .catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    const fetchServiceLocationStrings = (data, additionalProps) => {
        setshowLocationList(false)
        setopenPricePlan(false)
        setopenPriceSetup(false)
        $('#preloader').fadeIn('slow');

        Endpoint.getServiceLocationStrings(data)
        .then((res) => {
            setmarkedServicingArr(res?.data)
            setTimeout(() => {
                setshowLocationList(true)
                if(additionalProps == 2){
                    setopenPricePlan(true)
                }
                else if (additionalProps == 3) {
                    setopenPriceSetup(true)
                }
                $('#preloader').delay(450).fadeOut('slow');
            }, 1000);
            console.log(res?.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const handleSelectCatChange = (e) => {
        fetchServiceLocationStrings(parseInt(e.target.value))
        setcategogryId(parseInt(e.target.value))
    }
    const addServiceToLocations = () => {
        if (!inputValues?.serviceCategoryId && newLocationsArr?.length <= 0){
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Select a category and location(s) to continue</p>);
            return;
        }
        setsaving(true)
        Endpoint.addServiceLocations(newLocationsArr, categogryId)
        .then((res) => {
            console.log(res?.data)
            setsaving(false)
            toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Service Locations Added Successfully!</p>);
            handleCancel();
            serverLoad();
            setTimeout(() => {
                $('#preloader').fadeIn('slow');
                window.location.reload(true)
            }, 3000);
        })
        .catch((err) => {
            console.log(err)
            setsaving(false)
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Something went wrong!</p>);
        })
    }

    const applyServiceAmountToLocations = () => {
        console.log(JSON.stringify(featureList));

        // return;
        if (!inputValues?.plan_price) {
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Enter pricing amount!</p>);
            return;
        }
        if (featureList <= 0) {
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Enter atleast one pricing feature</p>);
            return;
        }
        if (!inputValues?.plan_name) {
            toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Enter pricing name</p>);
            return;
        }
        setsaving(true)

        const payload = {
            "serviceCategoryId": selectedServiceCategory?.id,
            "durationInHours": inputValues?.durationInHours,
            "price": inputValues?.plan_price,
            "name": inputValues?.plan_name,
            "locations": newLocationsArr,
            "planFeatures": JSON.stringify(featureList),
            "planType": 1,
        }

        Endpoint.applyPricingToLocation(payload)
            .then((res) => {
                console.log(res?.data)
                setsaving(false)
                toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Service Locations Added Successfully!</p>);
                handleCancel();
                serverLoad();
                setTimeout(() => {
                    $('#preloader').fadeIn('slow');
                    window.location.reload(true)
                }, 3000);
            })
            .catch((err) => {
                console.log(err)
                setsaving(false)
                toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Something went wrong!</p>);
            })
    }
    const handleCheckLocation = (data) => {
        console.log(data, "checkitems")
        setnewLocationsArr(data)
    }
    useEffect(() => {
        
        enquireScreen((b) => {
            setIsMobile(b)
        });
        serverLoad()
        
    }, []);

    const updtaePricePlans = () => {
        console.log(JSON.stringify(featureList));

        setsaving(true)

        const payload = {
            "serviceCategoryId": selectedServiceCategory?.id,
            "durationInHours": inputValues?.durationInHours,
            "price": inputValues?.plan_price,
            "name": inputValues?.plan_name,
            "locations": [],
            "planFeatures": JSON.stringify(featureList),
            "planType": 1,
        }

        Endpoint.updatePricing(payload, pricingPlanId)
            .then((res) => {
                console.log(res?.data)
                setsaving(false)
                toast.success(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Service Location updated Successfully!</p>);
                handleCancel();
                serverLoad();
                setTimeout(() => {
                    $('#preloader').fadeIn('slow');
                    window.location.reload(true)
                }, 3000);
            })
            .catch((err) => {
                console.log(err)
                setsaving(false)
                toast.error(<p className="outfit-500 text-white" style={{ fontSize: "15px" }}>Something went wrong!</p>);
            })
    }
    const handleShowPricingFields = (data) => {
        console.log(data, "Daaaa")
        if(data == null || data == undefined){
            setInputValues({
                plan_price: "",
                plan_name: "",
                durationInHours: "",
            });
            setpricingPlanAction("Apply ")
            setfeatureList([])
            setpricingSetupCard(true)
            return;
        }
        setpricingPlanId(data?.id)
        setpricingPlanAction("Update ")
        setInputValues({
            plan_price: parseFloat(data?.price),
            plan_name: data?.name,
            durationInHours: data?.durationInHours,
        });
        setfeatureList(data?.planFeatures)
        setpricingSetupCard(true)
    }
    const promptDelete = (data) => {
        setdeletePlanModal(true)
        setInputValues({
            selected_plan_name: data?.name,
        });
        setpricingPlanId(data?.id)
    }
    const serverLoad = () => {
        // fetchServiceLocationStrings();
        fetchAllServiceCategories();
    }
    const handleAddFeatures = () => {
        if(featureList?.length >= 5){
            toast.error(<p className="outfit-500 text-white text-center" style={{ fontSize: "15px" }}>Maximum pricing feaure allowed is five(5)</p>);
            return;
        }
        setfeatureList([...featureList, inputValues?.plan_features])
        document.getElementById("plan_features").value = ""
    }
    const handleRemoveFeatures = (data) => {
        var filterList = featureList?.filter((x) => x != data);
        setfeatureList(filterList);
    }
    const executeDelete = () => {
        setsaving(true)
        Endpoint.deletePricingPlan(pricingPlanId)
        .then((res) => {
            console.log()
            handleCancelDelete();
            handleCancel();
            alert("Successful")
            setsaving(false)
            window.location.reload(true)
        })
        .catch((err) => {
            console.log(err)
            setsaving(false)
        })
    }
    return (
        <>
            <ToastContainer
                position="top-center"
                theme="colored"
            />
            <Modal footer={[
                <button onClick={() => executeDelete()} className="btn btn-primary outfit-400" disabled={saving} style={{ marginRight: "10px" }}>
                    {saving ? <ScaleLoader color="#fff" width={2} height={16} /> : <span>Confirm <i class="fa-regular fa-circle-check"></i></span>}
                </button>,
                <button className="btn btn-danger outfit-400">Cancel</button>]} open={deletePlanModal} onOk={handleCancelDelete} onCancel={handleCancelDelete}>
                <p className="outfit-400">Are sure you want to delete <b>{inputValues?.selected_plan_name}</b></p>
            </Modal> 
            <Modal footer={[
                <button onClick={() => addServiceToLocations()} className="btn btn-primary outfit-400" disabled={saving} style={{ marginRight: "10px" }}>
                    {saving ? <ScaleLoader color="#fff" width={2} height={16} /> : <span>Add <i class="fa-regular fa-circle-check"></i></span>}
                </button>,
                <button className="btn btn-danger outfit-400">Cancel</button>]} title={<h3 className="outfit-500" style={{ fontSize: "20px" }}>Add Service Location</h3>} open={openAddServiceLocation} onOk={handleOk} onCancel={handleCancel}>
                <div className="row mt-4">
                    <div className="col-12">
                        <select onChange={(e) => handleSelectCatChange(e)} name="serviceCategoryId" className="form-select outfit-400" aria-label="Default select example">
                            <option selected>Select Service Category</option>
                            {cardDetails && cardDetails?.map((x, i) => {
                                return(
                                    <option value={x?.id}>{x?.name}</option>
                                )
                            })}
                        </select>
                    </div>
                   {showLocationList && 
                   
                        <div className="col-12 mt-3" style={{ maxHeight: "35em", overflow: "scroll" }}>
                            <TreeCompo checkedArr={markedServicingArr} showfetchFunc={false} disabledArr={markedServicingArr} disableState={1} addFunc={handleCheckLocation} />
                        </div>
                   }
                </div>
            </Modal>      
            <Modal footer={[
                <button onClick={modalText == "Add" ? () => handleCreateServiceCategory() : () => handleUpdateServiceCategory()} onCancel={handleCancel} className="btn btn-primary outfit-400" disabled={saving} style={{ marginRight: "10px" }}> 
                    {saving ? <ScaleLoader color="#fff" width={2} height={16} /> : <span>{modalText} <i class="fa-regular fa-circle-check"></i></span>}
                </button>, 
                <button  className="btn btn-danger outfit-400">Cancel</button>]} title={<h3 className="outfit-500" style={{fontSize:"20px"}}>{modalText} Service Category</h3>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
               <div className="row mt-4">
                   
                        <div className="col-12">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label outfit-500">Select Image <span style={{ fontSize: "14px", color: "red" }}> *</span></label>
                            {!previewImage && 
                                <Dragger
                                    customRequest={dummyRequest}
                                    onChange={handleChange}
                                    maxCount={1}
                                // defaultFileList={fileList}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text outfit-400" style={{ fontSize: "12px" }}>Click or drag file to this area to upload</p>
                                    <span className='span'></span> <span className='span outfit-400'>(jpg, jpeg, png)</span>
                                    {/* <p className="ant-upload-hint">
                                Support for a single or bulk upload
                            </p> */}
                                </Dragger>
                            }
                         
                            {previewImage && 
                            
                                <div>
                                    <img src={previewImage} class="img-fluid" alt="..." />
                                    <button className="btn btn-danger btn-sm outfit-500 float-end mt-1" onClick={() => clearImage()}><i class="fa-solid fa-trash"/></button>
                                </div>
                            }
                            </div>
                        
                        </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label outfit-500">Category Name <span style={{ fontSize: "14px", color:"red" }}> *</span></label>
                            <input onChange={(e) => handleInput(e)} name="categoryName" value={inputValues?.categoryName} type="text" className="form-control outfit-400" id="exampleFormControlInput1"  />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label outfit-500">Sub-Caption <span style={{ fontSize: "14px", color: "red" }}> *</span></label>
                            <input type="text" onChange={(e) => handleInput(e)} name="categoryCaption" value={inputValues?.categoryCaption} className="form-control outfit-400" id=""  />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label outfit-500">Badge Value <span style={{fontSize:"11px"}}>(Optional)</span></label>
                            <input type="text" onChange={(e) => handleInput(e)} name="badgeValue" value={inputValues?.badgeValue} className="form-control outfit-400" id="" />
                        </div>
                    </div>
                    {/* <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label outfit-500">Service Type <span style={{ fontSize: "11px" }}></span></label>
                            <select name="serviceType">
                                <option value={1}>Regular</option>
                                <option value={2}>On The Go</option>
                            </select>
                        </div>
                    </div> */}
                    <div className="col-12">
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label outfit-500">Description <span style={{ fontSize: "14px", color: "red" }}> *</span></label>
                            <textarea onChange={(e) => handleInput(e)} name="description" value={inputValues?.description} className="form-control outfit-500" id="" rows={3} defaultValue={""} />
                        </div>
                    </div>

                
               </div>
            </Modal>
            <Modal footer={[]} 
                title={<p className="outfit-400" style={{fontSize:"14px"}}>Service Locations for <span className="outfit-600" style={{ fontSize: "16px" }}>{selectedServiceCategory?.name}</span></p>} 
            open={openPricePlan} onOk={handleOk} onCancel={handleCancel} width={1200}>

              <div className="row">
                    <div className="col-3" >
                        {showLocationList && 
                            <div style={{ maxHeight: "55vh", overflow: "scroll" }}>
                                {/* <p className="outfit-400">Service Locations for <span className="outfit-600" style={{fontSize:"16px"}}>{selectedServiceCategory?.name}</span></p> */}
                                <TreeCompo checkedArr={markedServicingArr} showfetchFunc={true} fetchFunc={fetchPlansByLocation} disabledArr={markedServicingArr} disableState={1} addFunc={handleCheckLocation} />
                            </div>
                        }
                       
                        <button className="btn btn-primary btn-sm mt-3 outfit-500 text-center" style={{ background:"#1f5bb4"}}>Save <i className="fa fa-check-circle"/></button>
                    </div>
                    <div className="col-9" style={{ marginTop: "2em", marginLeft:"-3em" }}>
                       {emptyStateShow && !loading &&
                            <div className="col-sm-12" style={{ marginTop: "1em", justifyContent: "center" }}>
                                <div style={{ justifyContent: "center", display: "grid" }}>
                                    <center>
                                        <img src={emptyState} style={{ width: "204px" }} />
                                    </center>
                                    <h2 className="outfit-500 text-center">Select location to load price plan</h2>
                                    <p className="outfit-400 text-center" style={{ fontSize: "14px" }}>Price plans are loaded based on a selected location</p>
                                </div>
                            </div>
                       
                    }
                        <main className="main">
                            
                            <div className="cards row" id="cards">
                                {loading &&<div className="col-12" style={{ marginTop: "8em", marginLeft: "-15em" }}>
                                     <Spin
                                        size={"large"}
                                        style={{
                                            color: "geekblue",
                                            fontSize:"30px"

                                        }} indicator={<LoadingOutlined spin style={{
                                            fontSize: 48

                                        }} />} />
                        </div>}
                              
                               {pricePlanArr && pricePlanArr?.map((x, i) => {
                                return(
                                    <div className="price-card col-4" id="price-card">
                                        <div className="price-card--top">
                                            <h3 className="">{x?.name}</h3>
                                            <h5 className="outfit-300">₦ <span>{x?.price}</span><span className="price-text">/2hr</span></h5>
                                            <hr />
                                        </div>
                                        <div className="price-card--bottom">
                                            <ul>
                                                {x?.planFeatures && x.planFeatures?.map((p, k) => {
                                                    return(
                                                        <li className="outfit-400">
                                                            <i className="fa fa-check" />{p}
                                                        </li>
                                                    )
                                                })}
                                                {/* <li className="outfit-400">
                                                    <i className="fa fa-check" />Small/Quick tasks
                                                </li>
                                                <li className="outfit-400">
                                                    <i className="fa fa-check" />Light Cleaning
                                                </li>
                                                <li className="outfit-400">
                                                    <i className="fa fa-check" />One(1) Service Person
                                                </li> */}
                                            </ul>
                                            <button type="button" className="btn" id="btn"><i className="fa fa-edit"/> Edit</button>
                                        </div>
                                    </div>
                                )
                               })}
                                {/* <div className="price-card">
                                    <div className="price-card--top">
                                        <h3>Pro</h3>
                                        <h5>₦ <span className="price-pro">7,200</span><span className="price-text">/2-4hrs</span></h5>
                                        <hr />
                                    </div>
                                    <div className="price-card--bottom">
                                        <ul>
                                            <li className="outfit-400">
                                                <i className="fa fa-check" />Regular, routine tasks
                                            </li>
                                            <li className="outfit-400">
                                                <i className="fa fa-check" />Standard cleaning
                                            </li>
                                            <li className="outfit-400">
                                                <i className="fa fa-check" />Thorough and detailed
                                            </li>
                                            <li className="outfit-400">
                                                <i className="fa fa-check" />Two(2) Service Person
                                            </li>
                                        </ul>
                                        <button className="btn">Try it</button>
                                    </div>
                                </div> */}
                                {/* <div className="price-card">
                                    <div className="price-card--top">
                                        <h3>Extended</h3>
                                        <h5>₦ <span className="price-team">9,450</span><span className="price-text">/4-6hrs</span></h5>
                                        <hr />
                                    </div>
                                    <div className="price-card--bottom">
                                        <ul>

                                            <li className="outfit-400">
                                                <i className="fa fa-check" />Extensive assistance
                                            </li>
                                            <li className="outfit-400">
                                                <i className="fa fa-check" />Uninterupted assistance
                                            </li>
                                            <li className="outfit-400">
                                                <i className="fa fa-check" />Efficient service completion
                                            </li> <li className="outfit-400">
                                                <i className="fa fa-check" />Best for full-day assistance
                                            </li>
                                            <li className="outfit-400">
                                                <i className="fa fa-check" />Four(4) Service Person
                                            </li>
                                        </ul>
                                        <button className="btn">Try it</button>
                                    </div>
                                </div> */}
                                
                            </div>
                        </main>


                    </div>
              </div>
            </Modal>
            <Modal footer={[]}
                title={<p className="outfit-400" style={{ fontSize: "14px" }}>Pricing setup for <span className="outfit-600" style={{ fontSize: "16px" }}>{selectedServiceCategory?.name}</span></p>}
                open={openPriceSetup} onOk={handleOk} onCancel={handleCancel} width={1200}>

                <div className="row">
                    <div className="col-12">
                        <button onClick={() => handleShowPricingFields()} className="btn btn-primary btn-sm float-end outfit-500">New Pricing <i className="fa-solid fa-coins"/></button>
                    </div>
                    <div className="col-3" >
                        {showLocationList &&
                            <div style={{ maxHeight: "70vh", overflow: "scroll" }}>
                                {/* <p className="outfit-400">Service Locations for <span className="outfit-600" style={{fontSize:"16px"}}>{selectedServiceCategory?.name}</span></p> */}
                                <TreeCompo showfetchFunc={true} checkedArr={[]} fetchFunc={fetchPlansByLocation} disabledArr={markedServicingArr} 
                                disableState={2} addFunc={handleCheckLocation} />
                            </div>
                        }

                        {/* <button className="btn btn-primary btn-sm mt-3 outfit-500 text-center" style={{ background: "#1f5bb4" }}>Save <i className="fa fa-check-circle" /></button> */}
                    </div>
                    <div className="col-9" style={{ marginTop: "2em", marginLeft: "-3em", maxHeight: "70vh", overflow:"scroll" }}>
                        {pricingSetupCard && 
                            <div className="row justify-content-center ">
                                <div className="col-8 ">
                                  <div className="card shadow" style={{padding:"24px", maxHeight:"36em"}}>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fa-solid fa-coins" /></span>
                                            <input type="text" onChange={(e) => handleInput(e)} value={inputValues?.plan_name} name="plan_name" className="form-control outfit-400" placeholder="Plan Name" aria-label="Username" aria-describedby="basic-addon1" />
                                        </div>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-naira-sign"/></span>
                                            <input onChange={(e) => handleInput(e)} name="plan_price" type="text" className="form-control outfit-400" placeholder="Amount" value={inputValues?.plan_price} aria-label="Username" aria-describedby="basic-addon1" />
                                        </div>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-clock" /></span>
                                            <input onChange={(e) => handleInput(e)} value={inputValues?.durationInHours} name="durationInHours" type="text" className="form-control outfit-400" placeholder="Duration(In Hours)" aria-label="Username" aria-describedby="basic-addon1" />
                                        </div>

                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-scale-unbalanced" /></span>
                                            <input onChange={(e) => handleInput(e)} name="plan_features" id="plan_features" type="text" className="form-control outfit-400" placeholder="Features" aria-label="Username" aria-describedby="basic-addon1" />
                                            <span onClick={() => handleAddFeatures()} style={{cursor:"pointer"}} className="input-group-text" id="basic-addon1"><span className="outfit-400">Add</span> <MdChecklist/></span>
                                        </div>
                                       <div className="row mt-5">
                                            <p className="outfit-500 mb-3"><i className="fa-solid fa-coins" /> Feature List</p>
                                          <div className="col-12">
                                           {featureList && featureList?.map((x, i) => {
                                            return(
                                                <div className="row mb-2">
                                                    <div className="col-7">
                                                        <li className="outfit-400" style={{ color: "#4e4eed" }}>
                                                            <i className="fa fa-check" />&nbsp; {x}
                                                        </li>
                                                    </div>
                                                    <div className="col-3">
                                                        <li onClick={() => handleRemoveFeatures(x)} className="outfit-400" style={{ color: "#4e4eed", cursor:"pointer" }}>
                                                            <i className="fa fa-trash text-danger" />
                                                        </li>
                                                    </div>
                                                </div>

                                            )
                                           })}
                                            </div>
                                            
                                        </div>
                                        <div className="input-group mt-3">
                                            <button disabled={saving} onClick={pricingPlanAction == "Update " ? () => updtaePricePlans() : () => applyServiceAmountToLocations()} className="btn btn-primary outfit-500" style={{ width: "100%" }}>
                                                {saving && 
                                                    <ScaleLoader color="#fff" width={2} height={16} />
                                                }
                                                 
                                                {pricingPlanAction} 
                                                pricing <MdCheckCircleOutline /></button>
                                        </div>
                                    </div>
 
                                </div>

                            </div>
                        }
                        {!pricingSetupCard && emptyStateShow && !loading &&
                            <div className="col-sm-12" style={{ marginTop: "1em", justifyContent: "center" }}>
                                <div style={{ justifyContent: "center", display: "grid" }}>
                                    <center>
                                        <img src={emptyState} style={{ width: "204px" }} />
                                    </center>
                                    <h2 className="outfit-500 text-center">Select location to load price plan</h2>
                                    <p className="outfit-400 text-center" style={{ fontSize: "14px" }}>Price plans are loaded based on a selected location</p>
                                </div>
                            </div>

                        }
                      {!pricingSetupCard && 
                            <main className="main">

                                <div className="cards row" id="cards">
                                    {loading && <div className="col-12" style={{ marginTop: "8em", marginLeft: "-15em" }}>
                                        <Spin
                                            size={"large"}
                                            style={{
                                                color: "geekblue",
                                                fontSize: "30px"

                                            }} indicator={<LoadingOutlined spin style={{
                                                fontSize: 48

                                            }} />} />
                                    </div>}

                                    {pricePlanArr && pricePlanArr?.map((x, i) => {
                                        return (
                                            <div className="price-card col-4" id="price-card">
                                                <div className="price-card--top">
                                                    <h3 className="">{x?.name}</h3>
                                                    <h5 className="outfit-300">₦ <span>{x?.price}</span><span className="price-text">/{x?.durationInHours}</span></h5>
                                                    <hr />
                                                </div>
                                                <div className="price-card--bottom">
                                                    <ul>
                                                        {x?.planFeatures && x.planFeatures?.map((p, k) => {
                                                            return (
                                                                <li className="outfit-400">
                                                                    <i className="fa fa-check" />{p}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                   
                                                           <Space style={{padding:"20px"}}>
                                                        {/* <button onClick={() => handleShowPricingFields(x)} type="button" className="btn" id="btn"> */}
                                                        <i onClick={() => handleShowPricingFields(x)} className="fa fa-edit text-primary" style={{fontSize:"35px", cursor:"pointer"}}/> 
                                                            {/* Edit</button> */}

                                                        &nbsp;   | &nbsp;
                                                        {/* <button onClick={() => handleShowPricingFields(x)} type="button" className="btn btn-danger" id="btn"> */}
                                                        <i onClick={() => promptDelete(x)} className="fa fa-trash text-danger" style={{ fontSize: "35px", cursor: "pointer" }} /> 
                                                            {/* Delete</button> */}
                                                           </Space>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </main>
                      }


                    </div>
                </div>
            </Modal>
            <div id="preloader">
                <div id="status">
                    <ScaleLoader color="#fff" />
                </div>
            </div>
          
                <div>
                    <div className="row g-3 justify-content-center">
                        <div className="col-12 col-md-2 shadow stat-card-dash">
                            <div className="row">
                                <div className="col-4">
                                    <div className="stat-radial active-blue-bg">
                                        <MdBarChart
                                            style={{ fontSize: "25px", color: "#fff" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-8 ">
                                    <p className="outfit-500" style={{ color: "#a3aed0" }}>Total Services</p>
                                        <h3 style={{ lineHeight: "5px" }}>{cardDetails?.length}</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-2 shadow stat-card-dash">
                            <div className="row">
                                <div className="col-4">
                                        <div className="stat-radial active-green-bg" style={{ backgroundColor: "#f4f7fe" }}>
                                        <MdGroups
                                            style={{ fontSize: "25px", color: "#fff" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-8">
                                    <p className="outfit-500" style={{ color: "#a3aed0" }}>Servicing States</p>
                                    <h3 style={{ lineHeight: "5px" }}>-</h3>
                                </div>
                            </div>
                        </div>

                            <div className="col-12 col-md-2 shadow stat-card-dash">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="stat-radial " style={{ backgroundColor: "#f4f7fe" }}>
                                            <MdGroupOff
                                                style={{ fontSize: "25px", color: "#422AFB" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <p className="outfit-500" style={{ color: "#a3aed0" }}>Unserviced State</p>
                                        <h3 style={{ lineHeight: "5px" }}>-</h3>
                                    </div>
                                </div>
                            </div>
                        <div className="col-12 col-md-2 shadow stat-card-dash">
                            <div className="row">
                                <div className="col-4">
                                        <div className="stat-radial active-green-bg" style={{ backgroundColor: "#f4f7fe" }}>
                                            < MdGroup
                                            style={{ fontSize: "25px", color: "#fff" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-8">
                                        <p className="outfit-500" style={{ color: "#a3aed0" }}>Servicing Lgas</p>
                                    <h3 style={{ lineHeight: "5px" }}>-</h3>
                                </div>
                            </div>
                        </div>

                       

                        <div className="col-12 col-md-2 shadow stat-card-dash">
                            <div className="row">
                                <div className="col-4">
                                    <div className="stat-radial" style={{ backgroundColor: "#f4f7fe" }}>
                                        <MdAttachMoney
                                            style={{ fontSize: "25px", color: "#422AFB" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-8">
                                    <p className="outfit-500" style={{ color: "#a3aed0" }}>Weekly Revenue</p>
                                    <h3 style={{ lineHeight: "5px" }}></h3>
                                </div>
                            </div>
                        </div>

                    </div>
                        <div className="row mt-4 justify-content-center">
                            {visibilityIndicator == 1 && 
                               <div className="row">
                          {!isMobile ? 
                                <div className="col-12 mt-4">
                                    <Card>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <p className="outfit-600" style={{ fontSize: "18px" }}>
                                                    Service Category Management
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 text-right">
                                                <button onClick={() => showAddServiceLocationModal()} className="btn btn-primary btn-sm outfit-500 float-end" >
                                                    Add Service Location(s) <i className="fa-solid fa-location-dot" />
                                                </button>
                                                <button onClick={() => showModal()} className="btn btn-primary btn-sm outfit-500 float-end" style={{ marginRight: "5px" }}>
                                                    New Service Category <MdAddCircleOutline />
                                                </button>
                                            </div>

                                        </div>
                                        <Table columns={columns} dataSource={data} />
                                    </Card>
                                </div>  :
                               <p>Tables are not shown on mobile devices</p>  
                        }
                                </div>
                            }
                       
              {visibilityIndicator == 2 && 
              
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-5 mt-3">
                                        {/* <button> */}
                                    <i class="fa-solid fa-circle-arrow-left" onClick={() => toggleVisibility("", 1)} style={{ color:"#0d6efd", fontSize:"27px"}}></i>
                                        {/* </button> */}
                                        {/* <p className="outfit-600" style={{ fontSize: "22px" }}>
                                            House Cleaning
                                        </p> */}
                                    </div>
                                    <div className="col-12 col-md-6 text-right">
                                        <button onClick={() => showModal()} className="btn btn-primary outfit-500 float-end">
                                            New Price Plan <MdAddCircleOutline />
                                        </button>
                                    </div>
                                    {/* <div className="col-md-3 col-12 shadow mt-1" style={{ padding: "23px", borderRadius: "25px", marginRight: "10px" }}>
                                        <img className="bann-img" src={baseContentURL + selectedServiceCategory?.imageUrl} alt="..." />
                                        <p className="outfit-600 text-center" style={{ fontSize: "21px", color: "#1b2559" }}>{selectedServiceCategory?.name}</p>
                                        <div>
                                            <Badge.Ribbon text={selectedServiceCategory?.badgeText} color="green">
                                                <Card className="outfit-500" title="Caption" size="small">
                                                    {selectedServiceCategory?.subCaption}
                                                </Card>
                                            </Badge.Ribbon>
                                        </div>
                                        <div>
                                            <Badge.Ribbon text="" color="green">
                                                <Card className="outfit-500" title="Description" size="small">
                                                    {selectedServiceCategory?.description?.length > 200 ? selectedServiceCategory?.description?.substring(0, 200) + "..." : selectedServiceCategory?.description}
                                                </Card>
                                            </Badge.Ribbon>

                                        </div>
                                        <button className="btn btn-warning outfit-400 btn-sm mt-2"><i className="fa fa-edit" /> Edit</button>
                                    </div> */}
                                    {/* <div className="col-md-4 col-12 shadow mt-1" style={{ padding: "23px",  marginRight: "10px", maxHeight: "39.5em",  }}>
                                        
                                    <div style={{ overflow: "scroll", maxHeight: "34em", }}>
                                        <p className="outfit-600" style={{ fontSize: "21px", color: "#3db07e" }}><i style={{ fontSize: "18px" }} class="fa-solid fa-location-dot" /> Service Locations <span style={{ fontSize: "12px", color: "grey" }}>(States/Lgas)</span></p>
                                        <div className="mt-2"
                                        >
                                            <TreeCompo />
                                            
                                        </div>
                                        </div>
                                    <button className="btn btn-primary btn-sm mt-2 outfit-500 float-end"><i className="fa fa-save" /> Save selection(s)</button>
                                    </div> */}
                                    <div className="col-md-11 col-12 mt-1">
                                        <div className="pr-body">
                                            <header className="pr-header">
                                                <h1>Our Pricing</h1>
                                                <div className="pr-toggle">
                                                    <label>Annually </label>
                                                    <div className="pr-toggle-btn">
                                                        <input type="checkbox" className="checkbox" id="checkbox" />
                                                        <label className="sub" id="sub" htmlFor="checkbox">
                                                            <div className="circle" />
                                                        </label>
                                                    </div>
                                                    <label> Monthly</label>
                                                </div>
                                            </header>
                                            <div className="pr-cards">
                                                <div className="pr-card shadow">
                                                    <ul className="pr-ul">
                                                        <li className="pack">Basic</li>
                                                        <li id="basic" className="price bottom-bar">$199.99</li>
                                                        <li className="bottom-bar">500 GB Storage</li>
                                                        <li className="bottom-bar">2 Users Allowed</li>
                                                        <li className="bottom-bar">Send up to 3 GB</li>
                                                        <li><button className="btn">Learn More</button></li>
                                                    </ul>
                                                </div>
                                                <div className="pr-card active">
                                                    <ul className="pr-ul">
                                                        <li className="pack">Professional</li>
                                                        <li id="professional" className="price bottom-bar">$249.99</li>
                                                        <li className="bottom-bar">1 TB Storage</li>
                                                        <li className="bottom-bar">5 Users Allowed</li>
                                                        <li className="bottom-bar">Send up to 10 GB</li>
                                                        <li><button className="btn active-btn">Learn More</button></li>
                                                    </ul>
                                                </div>
                                                <div className="pr-card shadow">
                                                    <ul className="pr-ul">
                                                        <li className="pack">Master</li>
                                                        <li id="master" className="price bottom-bar">$399.99</li>
                                                        <li className="bottom-bar">2 TB Storage</li>
                                                        <li className="bottom-bar">10 Users Allowed</li>
                                                        <li className="bottom-bar">Send up to 20 GB</li>
                                                        <li><button className="pr-btn">Learn More</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="row">
                                            <div className="col-12 mt-1">
                                                <Card>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <p className="outfit-600" style={{ fontSize: "18px" }}>
                                                                Price Plans
                                                            </p>
                                                        </div>

                                                    </div>
                                                    {isMobile ? <h5>Tables are not shown on mobile devices</h5> : <Table columns={serviceplanscolumns} dataSource={data} />}
                                                </Card>
                                            </div>
                                        </div> */}

                                    </div>

                                </div>
              }
                    
                    </div>
                </div>
            
        </>
    );
};

export default withLayout(Sidenav)(ServiceCategoryManagement);
